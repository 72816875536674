import { render, staticRenderFns } from "./TagsContainer.vue?vue&type=template&id=33366769&scoped=true&"
import script from "./TagsContainer.vue?vue&type=script&lang=js&"
export * from "./TagsContainer.vue?vue&type=script&lang=js&"
import style0 from "./TagsContainer.vue?vue&type=style&index=0&id=33366769&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33366769",
  null
  
)

export default component.exports