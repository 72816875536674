import { render, staticRenderFns } from "./FollowersContainer.vue?vue&type=template&id=208fed18&scoped=true&"
import script from "./FollowersContainer.vue?vue&type=script&lang=js&"
export * from "./FollowersContainer.vue?vue&type=script&lang=js&"
import style0 from "./FollowersContainer.vue?vue&type=style&index=0&id=208fed18&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "208fed18",
  null
  
)

export default component.exports